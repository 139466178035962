import React from "react";
import Layout from "components/layout";
import LocationBanner from "components/locationBanner";
import WhyChooseUs from "components/homepage/whyChooseUs";
import Reviews from "components/homepage/reviews";
import Featured from "components/homepage/featured";
import GetInTouch from "components/getInTouch";
import TranslateAgency from "components/homepage/translateAgency";
import TranslationServices from "components/homepage/translationServices";

function ApiToken() {
  return (
    <Layout>
      <LocationBanner
        title="api-token-request"
        content="api-token-request"
        backgroundCol="bg-midBlue"
        formOrangeHead="Get in touch  "
      />
      <TranslateAgency head="An api-token-request Translation Company you can" />
      <div
        class="service_wrapper  container-fluid pb-80 pt-80"
        style="background: #00173A;"
      >
        <div class="container text-white">
          <div class="row">
            <div class="col-md-12 col-xs-12  orange">
              <h2 class="text-center">WPML API Token Request</h2>
              <div class="text-center pt-30">
                <p style="color: #e6e6e6; font-size: 16px; font-family: Open Sans;">
                  <img
                    src="https://skins.tomedes.com/frontend/images/location-template/badge.svg"
                    alt=""
                  />{" "}
                  1 Year Guarantee |{" "}
                  <img
                    src="https://skins.tomedes.com/frontend/images/location-template/call-center.svg"
                    alt=""
                  />{" "}
                  24/7 Availability |{" "}
                  <img
                    src="https://skins.tomedes.com/frontend/images/location-template/16-express.svg"
                    alt=""
                  />{" "}
                  Rapid Delivery
                </p>
              </div>
              <hr style="border-top: 1px solid #ff7b16!important;" />
              <p class="para-16 pt-20 pb-30 text-center">
                Request your WPML API token by clicking the link below, in order
                to partner with Tomedes and WPML.
              </p>
              <div class="col-md-2 col-xs-12">&nbsp;</div>
              <div class="col-md-8 col-xs-12">
                <form
                  id="formapi"
                  class="contactus_form"
                  action=""
                  enctype="multipart/form-data"
                  method="POST"
                  name="formapi"
                >
                  <input
                    id="formapi_url"
                    name="formapi_url"
                    type="hidden"
                    value="/footer/formapi"
                  />
                  <div class="row">
                    <div class="col-sm-6 col-md-6 form-group">
                      <span class="para-16">
                        Name<span style="color: red;">*</span>{" "}
                        <input
                          id="formapi_firstname"
                          class="pop_input form-control"
                          name="formapi_firstname"
                          type="text"
                          placeholder="Your first name"
                        />
                      </span>{" "}
                      <span
                        id="formapi_firstname_error"
                        style="color: red;"
                      ></span>
                    </div>
                    <div class="col-sm-6 col-md-6 form-group">
                      <span class="para-16">
                        Email<span style="color: red;">*</span>{" "}
                        <input
                          id="formapi_email"
                          class="pop_input form-control"
                          name="formapi_email"
                          type="text"
                          placeholder="Your email address"
                        />
                      </span>{" "}
                      <span id="formapi_email_error" style="color: red;"></span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 col-md-6 form-group">
                      <span class="para-16">
                        Website URL:<span style="color: red;">*</span>{" "}
                        <input
                          id="formapi_msg"
                          class="form-control"
                          name="formapi_msg"
                          type="text"
                          placeholder=""
                        />{" "}
                      </span>
                    </div>
                    <div class="col-sm-6 col-md-6 form-group">
                      <div
                        class="g-recaptcha"
                        data-sitekey="6LccHGMUAAAAADN732XlM4pVgAvumqA4UxbkeT7v"
                      >
                        &nbsp;
                      </div>
                      <div
                        id="captcha"
                        class="err-text"
                        style="position: absolute; bottom: 7px;"
                      ></div>

                      <div class="help-block with-errors">&nbsp;</div>
                    </div>
                  </div>
                  <div style="text-align: center!important;">
                    <button
                      id="formapi_submit"
                      class="fr-btn1"
                      name="formapi_submit"
                      type="button"
                    >
                      SEND REQUEST
                    </button>
                  </div>
                </form>
                <input
                  id="formapi_csrf_token"
                  name="formapi_csrf_token"
                  type="hidden"
                  value="54d5aee8f0719c7067bddcb460b9fcaf"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="service_wrapper  container-fluid  pb-80 pt-80"
        style="background: #000D21;"
      >
        <div class="container text-white">
          <div class="row">
            <div class="col-md-6 text-left ">
              <h2 class="pb-10">Having issues?</h2>
              <div class="para-16 pb-40 orange">
                <p>Contact us and we’ll be happy to help.</p>
                <p>
                  For more information on how to get started with WPML and
                  Tomedes, visit our{" "}
                  <strong>
                    <a
                      style="color: #ff7b16;"
                      href="https://www.tomedes.com/wpml-wordpress-website-translation"
                    >
                      WPML page.
                    </a>
                  </strong>
                </p>
              </div>
            </div>
            <div class="col-md-6 text-center blue">
              <div class="contactbtn">
                <a href="https://www.tomedes.com/contactus.php">
                  <span
                    class="fr-btn1"
                    style="text-align: center; width: 180px!important;"
                  >
                    CONTACT US
                  </span>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Reviews backgroundCol="bg-midBlue" />
      <TranslationServices LanguageName="api-token-request" />
      <Featured />
      <WhyChooseUs backgroundCol="bg-darkBlue" />
      <GetInTouch />
    </Layout>
  );
}

export default ApiToken;
